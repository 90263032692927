import './LandingPage.scss';
import Header from '../Components/Header/Header';
import Item from './Item/Item';
import PD from '../data';

import Footer from '../Components/Footer/Footer';

import { Helmet } from 'react-helmet';


function LandingPage({positive, negative, mode}) {

    let _PD = (mode === "alpha") ? PD.alpha : (mode === "beta") ? PD.beta : PD.gamma;
    _PD = positive ? _PD.positivePage : _PD.negativePage;

    return (
        <main className="landing-page">

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NWHWPGG" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

        <Helmet script={
            [{
                type: 'text/javascript',
                innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NWHWPGG');`
            }]
        }>
        </Helmet>

            <div className="landing-page-hero">
                <div className="container">
                    <div className="row" style={{marginBottom: 0}}>
                        <Header headerLinks={PD.navigationLinks} />
                    </div>
                    <div className="row">
                        
                        <div className="col s12 l6 landing-page-left">
                        {
                                _PD.hero.left.items.map((item, index) => {
                                    return (
                                        <Item item={item} key={index} />
                                    )
                                })
                        }
                        </div>

                        <div className="col s12 l6 landing-page-right">
                            {
                                _PD.hero.right.items.map((item, index) => {
                                    return (
                                        <Item item={item} key={index} />
                                    )
                                })
                            }
                        </div>
                    
                    </div>
                </div>
            </div>
            
            <div className="landing-page-content">
                <div className="container">
                    
                    <div className="landing-page-content-title container">
                        <h4 className="bold">{_PD.content.title}</h4>
                    </div>

                    <div className="row content-row">

                        <div className="col s12 l6">
                            
                            {
                                _PD.content.left.items.map((item, index) => {
                                    return (
                                        <Item item={item} key={index} />
                                    )
                                })
                            }
                            
                        </div>

                        <div className="col s12 l6">
                            
                            {
                                _PD.content.right.items.map((item, index) => {
                                    return (
                                        <Item item={item} key={index} />
                                    )
                                })
                            }
                            

                        </div>

                    </div>


                </div>
            </div>

            <Footer />

        </main>
    )
}

export default LandingPage;
