const pageData = {
    
    navigationLinks: [
        
    ],
    alpha: {
        positivePage: {
            hero: {
                left: {

                    items: [
                        {
                            type: "Heading",
                            text: "Unlock information intelligence",
                        },
                        {
                            type: "Text",
                            text: "Unparalleled visibility into your M365 information"
                        },
                        {
                            type: "List",
                            list: [
                                "Works out of the box",
                                "5 minutes to connect",
                                "No training or user involvement",
                                "Understands what types of information exist",
                                "Automatically expands the scope of MIP protection"
                            ]
                        }
                    ]

                },
                right: {
                    
                    items: [
                        {
                            type: "Form",
                            formItems: [
                                {
                                    type: "Form-Banner",
                                    src: "../../assets/form_banner.png"
                                },
                                {
                                    type: "Form-Image",
                                    src: "../../assets/positive_form_image.png",
                                },
                                {
                                    type: "Form-CTA",
                                    text: "Sign up to Cognni for free",
                                    href: "#!"
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p class="grey-text">Please note that sign-up only works for <b>global admins</b></p>`
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p>By clicking on the button you agree with our <a href="https://cognni.ai/privacy-policy/" target="_blank">privacy policy</a> and that your contact information can be used for marketing purposes. </p>`
                                }
                            ],
                        }
                    ]

                }
            },
            content: {
                title: "Autonomous mapping saves you 100s of hours of work every month:",
                left: {
                    items: [
                        {
                            type: "Video",
                            videoEmbedURL: "https://www.youtube.com/embed/NswTFrGI7ak",
                            thumbnail: "../../assets/video_thumbnail.png",
                        },
                        {
                            type: "PositiveCTA",
                            href: "#!",
                            text: "Sign up to Cognni for free"
                        }
                    ]
                },
                right: {
                    items: [
                        {
                            type: "Image",
                            src: "../../assets/lp_content_microsoft.png"
                        }
                    ]
                }
            }
        },
        negativePage: {
            hero: {
                left: {

                    items: [
                        {
                            type: "Heading",
                            text: "Schedule a demo to unlock information intelligence. ",
                        },
                        {
                            type: "Text",
                            text: "Unparalleled visibility into your M365 information"
                        },
                        {
                            type: "List",
                            list: [
                                "Works out of the box",
                                "5 minutes to connect",
                                "No training or user involvement",
                                "Understands what types of information exist",
                                "Automatically expands the scope of MIP protection"
                            ]
                        }
                    ]

                },
                right: {
                    
                    items: [
                        {
                            type: "Form",
                            formItems: [
                                {
                                    type: "Form-Banner",
                                    src: "../../assets/form_banner.png"
                                },
                                {
                                    type: "Form-Image",
                                    src: "../../assets/negative_form_image.png",
                                },
                                {
                                    type: "Meeting-Widget",
                                    CTA: {
                                        type: "CTA",
                                        href: "#",
                                        text: "Schedule a demo"
                                    }
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p>By clicking on the button you agree with our <a href="https://cognni.ai/privacy-policy/" target="_blank">privacy policy</a> and that your contact information can be used for marketing purposes. </p>`
                                }
                            ],
                        }
                    ]

                }
            },
            content: {
                title: "Autonomous mapping saves you 100s of hours of work every month:",
                left: {
                    items: [
                        {
                            type: "Video",
                            videoEmbedURL: "https://www.youtube.com/embed/NswTFrGI7ak",
                            thumbnail: "../../assets/video_thumbnail.png",
                        },
                        {    
                            type: "Meeting-Widget",
                            CTA: {
                                type: "CTA",
                                href: "#",
                                text: "Schedule a demo"
                            }
                        }
                    ]
                },
                right: {
                    items: [
                        {
                            type: "Image",
                            src: "../../assets/lp_content_microsoft.png"
                        }
                    ]
                }
            }
        }
    },
    beta: {
        positivePage: {
            hero: {
                left: {

                    items: [
                        {
                            type: "Heading",
                            text: "Schedule to unlock information intelligence",
                        },
                        {
                            type: "Text",
                            text: "Unparalleled visibility into your M365 information"
                        },
                        {
                            type: "List",
                            list: [
                                "Works out of the box",
                                "5 minutes to connect",
                                "No training or user involvement",
                                "Understands what types of information exist",
                                "Automatically expands the scope of MIP protection"
                            ]
                        }
                    ]

                },
                right: {
                    
                    items: [
                        {
                            type: "Form",
                            formItems: [
                                {
                                    type: "Form-Banner",
                                    src: "../../assets/form_banner.png"
                                },
                                {
                                    type: "Form-Image",
                                    src: "../../assets/positive_form_image.png",
                                },
                                {
                                    type: "Form-CTA",
                                    text: "Sign up to Cognni for free",
                                    href: "#!"
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p class="grey-text">Please note that sign-up only works for <b>global admins</b></p>`
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p>By clicking on the button you agree with our <a href="https://cognni.ai/privacy-policy/" target="_blank">privacy policy</a> and that your contact information can be used for marketing purposes. </p>`
                                }
                            ],
                        }
                    ]

                }
            },
            content: {
                title: "Autonomous mapping saves you 100s of hours of work every month:",
                left: {
                    items: [
                        {
                            type: "Video",
                            videoEmbedURL: "https://www.youtube.com/embed/NswTFrGI7ak",
                            thumbnail: "../../assets/video_thumbnail.png",
                        },
                        {
                            type: "PositiveCTA",
                            href: "#!",
                            text: "Sign up to Cognni for free"
                        }
                    ]
                },
                right: {
                    items: [
                        {
                            type: "Image",
                            src: "../../assets/lp_content_microsoft.png"
                        }
                    ]
                }
            }
        },
        negativePage: {
            hero: {
                left: {

                    items: [
                        {
                            type: "Heading",
                            text: "Schedule a demo to unlock information intelligence. ",
                        },
                        {
                            type: "Text",
                            text: "Unparalleled visibility into your M365 information"
                        },
                        {
                            type: "List",
                            list: [
                                "Works out of the box",
                                "5 minutes to connect",
                                "No training or user involvement",
                                "Understands what types of information exist",
                                "Automatically expands the scope of MIP protection"
                            ]
                        }
                    ]

                },
                right: {
                    
                    items: [
                        {
                            type: "Form",
                            formItems: [
                                {
                                    type: "Form-Banner",
                                    src: "../../assets/form_banner.png"
                                },
                                {
                                    type: "Form-Image",
                                    src: "../../assets/negative_form_image.png",
                                },
                                {
                                    type: "Form-Input-Group",
                                    groupName: "schedule_demo",
                                    inputs: [
                                        {
                                            type: "Form-Input",
                                            id: "first_name",
                                            label: "First name",
                                            inputType: "text",
                                        },
                                        {
                                            type: "Form-Input",
                                            id: "last_name",
                                            label: "Last name",
                                            inputType: "text",
                                        },
                                        {
                                            type: "Form-Input",
                                            id: "job_title",
                                            label: "Job Title",
                                            inputType: "text",
                                        },
                                        {
                                            type: "Form-Input",
                                            id: "work_email",
                                            label: "Work email",
                                            inputType: "email",
                                        },
                                    ]
                                },
                                {
                                    type: "Form-CTA",
                                    text: "Submit",
                                    href: null,
                                    handleSubmission: "schedule_demo",
                                    errorMessage: "Please fill all fields before proceeding forward!"
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p>By clicking on the button you agree with our <a href="https://cognni.ai/privacy-policy/" target="_blank">privacy policy</a> and that your contact information can be used for marketing purposes. </p>`
                                }
                            ],
                        }
                    ]

                }
            },
            content: {
                title: "Autonomous mapping saves you 100s of hours of work every month:",
                left: {
                    items: [
                        {
                            type: "Video",
                            videoEmbedURL: "https://www.youtube.com/embed/NswTFrGI7ak",
                            thumbnail: "../../assets/video_thumbnail.png",
                        },
                        {
                            type: "CTA",
                            href: "#",
                            text: "Schedule a demo"
                        }
                    ]
                },
                right: {
                    items: [
                        {
                            type: "Image",
                            src: "../../assets/lp_content_microsoft.png"
                        }
                    ]
                }
            }
        }
    },
    gamma: {
        positivePage: {
            hero: {
                left: {

                    items: [
                        {
                            type: "Heading",
                            text: "Schedule an onboarding to unlock information intelligence. ",
                        },
                        {
                            type: "Text",
                            text: "Unparalleled visibility into your M365 information"
                        },
                        {
                            type: "List",
                            list: [
                                "Works out of the box",
                                "5 minutes to connect",
                                "No training or user involvement",
                                "Understands what types of information exist",
                                "Automatically expands the scope of MIP protection"
                            ]
                        }
                    ]

                },
                right: {
                    
                    items: [
                        {
                            type: "Form",
                            formItems: [
                                {
                                    type: "Form-Banner",
                                    src: "../../assets/form_banner.png"
                                },
                                {
                                    type: "Form-Image",
                                    src: "../../assets/negative_form_image.png",
                                },
                                {
                                    type: "Meeting-Widget",
                                    CTA: {
                                        type: "CTA",
                                        href: "#",
                                        text: "Schedule an onboarding"
                                    }
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p class="green-text"><b>Please ensure to invite the <span class="bolder">global admin user</span> to this call.</b></p>`
                                },
                                {
                                    type: "Form-Info",
                                    text: `<p>By clicking on the button you agree with our <a href="https://cognni.ai/privacy-policy/" target="_blank">privacy policy</a> and that your contact information can be used for marketing purposes. </p>`
                                }
                            ],
                        }
                    ]

                }
            },
            content: {
                title: "Autonomous mapping saves you 100s of hours of work every month:",
                left: {
                    items: [
                        {
                            type: "Video",
                            videoEmbedURL: "https://www.youtube.com/embed/NswTFrGI7ak",
                            thumbnail: "../../assets/video_thumbnail.png",
                        },
                        {    
                            type: "Meeting-Widget",
                            CTA: {
                                type: "CTA",
                                href: "#",
                                text: "Schedule an onboarding"
                            }
                        }
                    ]
                },
                right: {
                    items: [
                        {
                            type: "Image",
                            src: "../../assets/lp_content_microsoft.png"
                        }
                    ]
                }
            }
        }
    },
    
}

module.exports = pageData;
