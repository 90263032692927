import './ThankYouPage.scss';

import Header from '../Components/Header/Header';

import ThankYouInfographic from './assets/thank-you-graphic.svg';

import { Helmet } from 'react-helmet';

const ThankYouPage = ({mode}) => {
    return (
        <div className="thank-you-page">

            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NWHWPGG" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

            <Helmet script={
                [{
                    type: 'text/javascript',
                    innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NWHWPGG');`
                }]
            }>
                <title>Thank you - Cognni AI</title>
            </Helmet>
            
            <div className="container">

                <div className="row">
                    <Header/>
                </div>

                <div className="center-align">

                    {
                        mode === 'alpha' &&
                        <div className="thank-you-content">
                            <h3 className="bolder">
                            Thank you for scheduling a demo!
                            </h3>
                            <h6>
                                If you have any questions, please contact <a href="mailto:support@cognni.ai">support@cognni.ai</a>
                            </h6>
                        </div>
                    }

                    {
                        mode === 'beta' &&
                        <div className="thank-you-content">
                            <h3 className="bolder">
                            Thank you for leaving your details.
                            </h3>
                            <h3 className="bold" style={{marginTop: 0, marginBottom: 50}}>We will be in touch shortly.</h3>
                            <h6>
                                If you have any questions, please contact <a href="mailto:support@cognni.ai">support@cognni.ai</a>
                            </h6>
                        </div>
                    }

                </div>

                <img 
                    src={ThankYouInfographic} 
                    className="thank-you-graphic" 
                    alt="" />

            </div>
            
        </div>
    )
}

export default ThankYouPage;