import './CTAButton.scss';
import {useState} from 'react';

function CTAButton({text, filled, onClick, href}) {

    const [btnClicked, setBtnClicked] = useState(false);

    const handleClick = () => {

        if (href) {
            console.log(href);
            window.location.replace('https://onboard.cognni.ai/consent?p=936af988-2d21-4f79-8598-b0dbdd469bfe');
            return;
        }

        setBtnClicked(true);
    
        if (typeof onClick === "function") {
            onClick();
        }

    }

    return (
        <a href={ (typeof onClick === "function") ? "#!" : onClick } className="black-text">
            <div onClick={handleClick} className={"cta-button center-align" + (filled ? " filled" : "") + (btnClicked ? " clicked": "")}>{text}</div>
        </a>
    )
}

export default CTAButton;

CTAButton.defaultProps = {
    onClick: () => {},
    href: null,
}