import './QuestionPage.scss';
import Header from '../Components/Header/Header';
import CTAButton from '../Components/CTAButton/CTAButton';

function QuestionPage({handlePositive, handleNegative, mode}) {
    return (
        <main className="question-page-wrapper center-align">

            <div className="main-content container">

                <Header/>
            
                <div className="question-page-content">

                    <div className="row">
                        <h4 className="bold">Intelligence tools to simplify your work life.</h4>
                        <h5>Unparalleled visibility into your company's information.</h5>
                    </div>

                    <div className="row">
                        <h4 className="bolder cognni-blue-text">Let's get you connected!</h4>
                    </div>

                    <div className="row">
                        <h5 className="bold">Are you a M365 global admin?</h5>
                    </div>

                </div>

                <div className="question-page-cta valign-wrapper">

                    <CTAButton 
                        text="YES" 
                        filled={true} 
                        href="https://onboard.cognni.ai/consent?p=936af988-2d21-4f79-8598-b0dbdd469bfe"
                         />

                    <CTAButton
                        text="NO" 
                        filled={false}
                        onClick={handleNegative} />

                </div>
                
            </div>

        </main>
    )
}

export default QuestionPage;

QuestionPage.defaultProps = {
    handlePositive: () => {},
    handleNegative: () => {}
}