import './Footer.scss';

function Footer() {


    return (
        <footer>
            <div className="footer-content row container">
                
                <div className="col xl3 m12 s12 footer-logo-section">

                    <div className="footer-logo">
                        <img src="assets/logo.png" draggable="false" width="50%" alt=""/>
                    </div>

                </div>

                <div className="col xl3 m12 s12 footer-resources-section">

                    <div className="resource-heading">Navigation</div>

                    <a className="resource-link" href="https://cognni.ai/cloud-intelligence">Cloud Intelligence</a>
                    <a className="resource-link" href="https://cognni.ai/on-premises-mapping/">On-Premise Intelligence</a>
                    <a className="resource-link" href="https://cognni.ai/use-cases/">Use Cases</a>
                    <a className="resource-link" href="https://cognni.ai/news-events/">{"News & Events"}</a>
                    <a className="resource-link" href="https://cognni.ai/partners">Become a partner</a>


                </div>

                <div className="col xl3 m12 s12 footer-company-section">

                    <div className="company-heading">Legal</div>

                    <a className="company-link" href="https://cognni.ai/terms-of-use/">Terms of Use</a>
                    <a className="company-link" href="https://cognni.ai/privacy-policy/">Privacy Policy</a>

                </div>

                <div className="col xl3 m12 s12 footer-company-section">

                    <div className="company-heading">Contact</div>

                    <a className="company-link" href="https://cognni.ai/contact-support/">Support</a>
                    <a className="company-link" href="https://cognni.ai/contact-general/">General Enquiries</a>
 
                    <a target="_blank" href="https://www.linkedin.com/company/10297664" rel="noreferrer" class="fab fa-linkedin-in black-text"></a>
                    
                    <a target="_blank" href="https://www.youtube.com/channel/UC5_GSCT8nMZPa9Q03K_CEzg" rel="noreferrer" class="black-text fab fa-youtube"></a>

                </div>


                <p className="col s12 footer-copyright">&copy; 2020 Copyright. Cognni</p>

            </div>
        </footer>
    );

}

export default Footer;
