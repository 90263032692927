import './Item.scss';
import {useState} from 'react';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

import Modal from 'simple-react-modal'


function Item ({item}) {

    const getItem = (item) => {
        switch (item.type) {
            case 'Text':
                return <Text item={item}/>;
            case 'Heading':
                return <Heading item={item}/>;
            case 'List':
                return <List item={item}/>;
            case 'Form':
                return <Form item={item}/>;
            case 'Video':
                return <VideoInterface 
                            videoEmbedURL={item.videoEmbedURL}
                            src={item.thumbnail} />;
            case "Image":
                return <Image item={item} />;
            case "CTA":
                return <CTA item={item} />;
            case "PositiveCTA":
                return <PositiveCTA item={item} onClick={sendM365Notification} />;
            case "Meeting-Widget":
                return <MeetingWidget item={item} />;
            default:
                console.log("Unhandled component type!");
        }
    }

    return (
        <>
        {getItem(item)}
        </>
    )
}

export default Item;

const CTA = ({item}) => {

    return (
        <div className="item-cta-button-container">
            <a href={item.href} className="btn btn-flat item-cta-button center-align">{item.text}</a>
        </div>
    )

}

const PositiveCTA = ({item, onClick})=> {
    return (
        <div className="item-cta-button-container">
            <a href={item.href} onClick={onClick} className="btn btn-flat item-cta-button center-align">{item.text}</a>
        </div>
    )
}

const Image = ({item}) => {
    return (
        <div className="image-container">
            <img src={item.src} className="form-item-image" alt="" draggable="false" width="100%" />
        </div>
    )
}

const Text = ({item}) => {
    return (
        <div className="item item-text">
            {item.text}
        </div>
    )
}

const Heading = ({item}) => {
    return (
        <h4 className="item item-heading bold">
            {item.text}
        </h4>
    )
}

const List = ({item}) => {
    return (
        <ul className="item item-list">
            {item.list.map((listItem) => {
                return (
                    <li className="item-list-item">
                        {listItem}
                    </li>
                )
            })}
        </ul>
    )
}

const Form = ({item}) => {
    return (
        <div className="item-form form-container">

                <div className="form-content white">
                    {
                        item.formItems.map((i, index)=> {
                            return <FormItem item={i} key={index} />
                        })
                    }
                </div>
        </div>
    )
}

const FormItem = ({item}) => {

    const getFormItem = (item) => {

        switch (item.type) {
            case 'Form-Image':
                return <FormImage item={item} />;
            case 'Form-CTA':
                return <FormCTA item={item} />;
            case 'Form-Info':
                return <FormInfo item={item} />;
            case "Form-Banner":
                return <FormBanner item={item} />;
            case "Form-Input-Group":
                return <FormInputGroup item={item} />;
            case "Meeting-Widget":
                return <MeetingWidget item={item} />;
            default:
                console.log("Unhandled component type!");
        }
    }

    return (
        <div>
            {
                getFormItem(item)
            }
        </div>
    )
}

const FormInputGroup = ({item}) => {
    return (
        <div className="form-item-input-group row">
            {
                item.inputs.map((i, index)=> {
                    return (
                        <div className="col s6">
                            <FormInput item={i} key={index} groupName={item.groupName} />
                        </div>
                    );
                })
            }
        </div>
    )
}

const FormInput = ({item, groupName}) => {
    return (
        <input 
            type={item.inputType} 
            className={"group__" + groupName} 
            placeholder={item.label} 
            name={item.id}
            required="true" />
    )
}

const FormBanner = ({item}) => {
    return (
        <img src={item.src} className="form-item-banner" alt="" draggable="false" />
    )
}

const FormImage = ({item}) => {
    return (
        <img src={item.src} className="form-item-image" alt="" draggable="false" />
    )
}

const FormCTA = ({item, onClick}) => {

    const handleClick = ()=> {
        
        if (onClick !== null) {
            return onClick();
        }

        if (!item.href) {
            
            let group_name = item.handleSubmission;
            let input_fields = document.getElementsByClassName(`group__${group_name}`);
            let error_message = document.getElementsByClassName(`group_err_msg__${group_name}`)[0];

            let showError = false;

            let form_data = {};
            for (let i = 0; i < input_fields.length; i++) {

                if (input_fields[i].value === '') {
                    input_fields[i].classList.add('empty');
                    showError = true;
                }
                else {
                    input_fields[i].classList.remove('empty');
                }
                
                form_data[input_fields[i].name] = input_fields[i].value;
            }

            if (showError) {
                error_message.classList.remove('hide');
            }
            else {
                error_message.classList.add('hide');
                handleSubmission(form_data);
            }


        }
        else {

            // Handle Click Submission Here...
            sendM365Notification();

        }
        
    }

    return (
        <div className="form-item-cta-container">
            <a href={item.href ? item.href : "#!"} className="btn btn-flat form-item-cta" onClick={handleClick}>{item.text} <i className="material-icons white-text right">chevron_right</i></a>
            <div className={`red-text group_err_msg__${item.handleSubmission} hide`}>{item.errorMessage}</div>
        </div>
    )
}

FormCTA.defaultProps = {
    onClick: null
}

const FormInfo = ({item}) => {
    return (
        <div className="form-item-info" dangerouslySetInnerHTML={{__html: item.text}} />
    )
}

const MeetingWidget = ({item})=> {

    const [modalOpen, setModalOpen] = useState(false);

    const handleModalOpen = () => {

        setModalOpen(true);

        const script = document.createElement("script");
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        script.async = true;
        document.body.appendChild(script);

        // Send Model Onboarding Event
        sendModalOnboardingNotification();

    }

    return (
        <div>

            <FormCTA item={item.CTA} onClick={handleModalOpen} />

            <Modal
                show={modalOpen}
                containerClassName="meeting-widget-modal-container"
                containerStyle={{
                    width: '100vw',
                    maxWidth: '920px',
                    margin: '0px auto 10% auto',
                    paddingBottom: '0px',
                    paddingTop: '3rem',
                    transform: 'scale(0.7)',
                    boxShadow: '2px 2px 25px 0px rgba(0, 0, 0, 0.5)',
                    top: '-3rem'
                }}>
                    
                    <div className="meeting-widget">
                        <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/cognni?embed=true"></div>
                        <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>
                    </div>

                    <a href="#!" className="modal-close" onClick={()=> { setModalOpen(false); window.location.href = "/thank-you-alpha"}}> <i className="material-icons">close</i> </a>

            </Modal>

            
        </div>
    )
}


const VideoInterface = ({src, videoEmbedURL})=> {

    // default state
    const [videoClicked, setVideoClicked] = useState(true);

    return (
        <div>

            <Video 
                videoEmbedURL={videoEmbedURL}
                videoClicked={videoClicked} />
{/* 
            <Thumbnail 
                src={src}
                setVideoClicked={setVideoClicked}
                videoClicked={videoClicked}
            />
             */}
        </div>
    )

}

const Thumbnail = ({src, setVideoClicked, videoClicked})=> {

    const hideThumbnail = ()=> {
        setVideoClicked(true);
    }

    return (
        <div className={"video-thumbnail" + (videoClicked ? " hide" : "")}>
            
            <img src={src ? src : ""} width="100%" alt=""/>

            <img src="../../assets/video_play_btn.png" alt="Play Btn" className="play-btn" onClick={hideThumbnail} />
        
        </div>
    )
}

const Video = ({videoEmbedURL, videoClicked})=> {
    return (
        <div className={"video-container" + (videoClicked ? "" : " invisible")}>
            <iframe width="100%" height="350" src={videoEmbedURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
        </div>
    )
}


const sendM365Notification = ()=> {


    // storing uuid in cookie
    // checking if cookie exists
    if (!document.cookie.includes('deviceId')) {
        document.cookie = `deviceId=${uuidv4()}`;
    }

    let deviceId = document.cookie.split('=');
    deviceId = deviceId[1];

    const ONBOARD_API_URL = 'https://stage-webapi.cognni.ai/analytic/events/onboarding';
    const ONBOARD_API_HEADERS = {
        'Authorization': "Basic NWM4MDE2NTItZjZhOS00NjdlLTk5NjgtNmZmNjUxMWRlYWEyOiYxPGhiXVZCYHZVTypgRHo8bXQrWWsrJjc5VGxZWWQwJD0qb0JmaUktZXpXUFRbNEAxaG5oR2RfJ1VeIUtOWg==",
        'Content-Type': 'application/json',
        "Origin": "lp.cognni.ai"
    }
    const date_obj = new Date();
    const ONBOARD_DATA = {
        partnerId: "48159c48-4086-40e0-8838-6762caad3d84",
        tenantId: deviceId,
        eventId: "landing_page_m365_admin_click",
        eventTime: date_obj.getHours() + ':' + date_obj.getMinutes() + ':' + date_obj.getSeconds(),
    }

    axios.post(
        ONBOARD_API_URL,
        ONBOARD_DATA,
        {
            headers: ONBOARD_API_HEADERS
        }
    )
    .then(res => {
        
        window.location.href = `https://onboard.cognni.ai/consent?p=48159c48-4086-40e0-8838-6762caad3d84`;

    });

}

// this handles the form submission
const handleSubmission = (form_data)=> {


    // storing uuid in cookie
    // checking if cookie exists
    if (!document.cookie.includes('deviceId')) {
        document.cookie = `deviceId=${uuidv4()}`;
    }

    let deviceId = document.cookie.split('=');
    deviceId = deviceId[1];

    const ONBOARD_API_URL = 'https://stage-webapi.cognni.ai/analytic/events/landingpage';
    const ONBOARD_API_HEADERS = {
        'Authorization': "Basic NWM4MDE2NTItZjZhOS00NjdlLTk5NjgtNmZmNjUxMWRlYWEyOiYxPGhiXVZCYHZVTypgRHo8bXQrWWsrJjc5VGxZWWQwJD0qb0JmaUktZXpXUFRbNEAxaG5oR2RfJ1VeIUtOWg==",
        'Content-Type': 'application/json',
        'Origin': "lp.cognni.ai/page-beta"
    }
    const date_obj = new Date();
    const ONBOARD_DATA = {
        partnerId: "48159c48-4086-40e0-8838-6762caad3d84",
        tenantId: deviceId,
        eventId: "form_submission_conversion_landing_page_beta",
        eventTime: date_obj.getHours() + ':' + date_obj.getMinutes() + ':' + date_obj.getSeconds(),
        data: form_data
    }

    axios.post(
        ONBOARD_API_URL,
        ONBOARD_DATA,
        {
            headers: ONBOARD_API_HEADERS
        }
    )
    .then(res => {
        
        window.location.href = `/thank-you-beta`;

    });

}

const sendModalOnboardingNotification = ()=> {
    
    // storing uuid in cookie
    // checking if cookie exists
    if (!document.cookie.includes('deviceId')) {
        document.cookie = `deviceId=${uuidv4()}`;
    }

    let deviceId = document.cookie.split('=');
    deviceId = deviceId[1];

    const ONBOARD_API_URL = 'https://stage-webapi.cognni.ai/analytic/events/onboarding';
    const ONBOARD_API_HEADERS = {
        'Authorization': "Basic NWM4MDE2NTItZjZhOS00NjdlLTk5NjgtNmZmNjUxMWRlYWEyOiYxPGhiXVZCYHZVTypgRHo8bXQrWWsrJjc5VGxZWWQwJD0qb0JmaUktZXpXUFRbNEAxaG5oR2RfJ1VeIUtOWg==",
        'Content-Type': 'application/json',
        'Origin': 'lp.cognni.ai/page-alpha'
    }
    const date_obj = new Date();
    const ONBOARD_DATA = {
        partnerId: "48159c48-4086-40e0-8838-6762caad3d84",
        tenantId: deviceId,
        eventId: "landing_page_meeting_btn_click",
        eventTime: date_obj.getHours() + ':' + date_obj.getMinutes() + ':' + date_obj.getSeconds(),
    }

    axios.post(
        ONBOARD_API_URL,
        ONBOARD_DATA,
        {
            headers: ONBOARD_API_HEADERS
        }
    )
}