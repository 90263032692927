import './fonts/Fonts.css';
import './App.scss';

import QuestionPage from './QuestionPage/QuestionPage';

import LandingPage from './LandingPage/LandingPage';

import ThankYouPage from './ThankYouPage/ThankYouPage';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

function App() {

  return (
    <div className="App">

      <Router>

        <Routes>

          {/* -- LP1 -- */}
          <Route exact path="/" element={<QuestionPage mode={"page-alpha"} handlePositive={"/m365-admin"} handleNegative={"/m365-no-admin"} />} />
          <Route exact path="/m365-admin" element={ <LandingPage positive={true} mode="alpha" /> } />
          <Route exact path="/m365-no-admin" element={ <LandingPage negative={true} mode="beta" /> } />


          {/* -- LP3 -- */}
          <Route exact path="/lp3" element={ <LandingPage negative={true} mode="alpha" /> } />
          
          {/* -- LP4 -- */}
          <Route exact path="/lp4" element={ <LandingPage positive={true} mode="gamma" /> } />

          {/* Thank you routing */}
          
          <Route exact path="/thank-you-alpha" element={ <ThankYouPage mode="alpha" /> } />
          <Route exact path="/thank-you-beta" element={ <ThankYouPage mode="beta" /> } />

        </Routes>
      
      </Router>

    </div>
  );
}

export default App;
